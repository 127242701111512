import DefaultList from '../List';

export default class ArchivedRuleList extends DefaultList {
  getCrumbs() {
    return [
      {
        destination: `/services/processing-crud-service/rules/list`,
        displayId: `au.entity.title.rules`,
        key: `crumb_rules`
      },
      {
        destination: `/services/processing-crud-service/archivedrules/list`,
        displayId: `au.entity.title.archivedRule`,
        key: `crumb_archivedRule`
      }
    ];
  }
}