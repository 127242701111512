import React from 'react';
import * as T from 'prop-types';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import styles from '../../css/components/entity_breadcrumbs.module.scss';

export default class Breadcrumbs extends React.Component {

  static propTypes = {
    className: T.string,
    crumbs: T.arrayOf(T.shape({
      key: T.string,
      displayId: T.string,
      displayString: T.string,
      destination: T.string,
      tag: T.string
    })).isRequired
  }

  render() {
    const { className, crumbs } = this.props;
    const len = crumbs?.length;

    if (!len) {
      return false;
    }

    const elements = [];
    crumbs.forEach((crumb, i) => {
      let { key, displayId, displayString, destination, tag } = crumbs[i];
      let props = { displayId, displayString };

      if (i < len - 1 && destination) {
        // render all the entries with "destination" as a links, except the last one
        elements.push(
          <div key={key} className={styles.wrapper}>
            <NavLink className={styles.crumb} to={destination} data-tag={tag}>
              <AutoIntl {...props} />
            </NavLink>
          </div>
        );
      } else {
        // render last entry as a plain text
        elements.push(
          <div key={crumb.key} className={styles.wrapper}>
            <AutoIntl
              {...props}
              className={cn(styles.crumb, crumb.className)}
              data-tag={tag}
            />
          </div>
        );
      }

      if (i < len - 1) {
        elements.push(<span key={`crumbs_arrow_${i}`} className={styles.arrow} />);
      }
    });

    return (
      <div className={cn(styles.container, styles[`count_${len}`], className)} data-count={len}>
        { elements }
      </div>
    );
  }

}
