import React from "react";

import AuButton, {
    BUTTON_TYPE_TERTIARY
  } from '@au/core/lib/components/elements/AuButton';

import { history as browserHistory } from '../../../history';
import DefaultList from '../List';
import Banner, {BANNER_TYPE_INFO_SQUARE} from "../../Banner";

import styles from '../../../css/components/entity_list.module.scss';

export default class RulesList extends DefaultList {

  onBannerBtnClick = this.onBannerBtnClick.bind(this);
  onBannerBtnClick() {
    browserHistory.push({
      pathname: `${this.baseUrl.replace('/rules', '/lookup')}`,
      state: {prevUrl: this.baseUrl + `list` }
    })
  }

  getBanner() {

    const bannerButton = <AuButton type={BUTTON_TYPE_TERTIARY} displayId={'au.permitsLookup.lookupPermits'} onClick={this.onBannerBtnClick}/>;

    return <Banner className={styles.button_banner} type={BANNER_TYPE_INFO_SQUARE} displayId={'au.rules.bannerText'} children={bannerButton}/>;
  }
}