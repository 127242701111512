import React from 'react';
import { FormattedMessage, FormattedNumber, FormattedDate } from 'react-intl';
import { fromJS } from 'immutable';
import moment from 'moment-timezone';
import cn from 'classnames';

import TMC from "@autonomic/browser-sdk";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import { createResponseAlertMessage } from "@au/core/lib/components/objects/AlertMessage";
import SearchBox from '@au/core/lib/components/elements/SearchBox';
import DynamicDataTable from '@au/core/lib/components/elements/dynamic-data-table';

import { formatMessage } from './../utils/reactIntl';
import Breadcrumbs from "./entity/Breadcrumbs";
import TimezoneSelector from './TimezoneSelector';
import { getDateOptions } from '../utils/formatters';
import styles from '../css/components/lookup_result.module.scss';


const TZ = moment.tz.guess();

const tableDef = {
  "tableId": 'results',
  "tablePkField": "fieldId",
  "searchbox": true,
  "searchBoxPlaceholder": "Search",
  "columnDefs": [
    {
      "property": "fieldId",
      "label": "Field",
      "labelId": "au.permitsLookup.fieldId",
      "searchable": true,
      "sortable": true,
      "display" : true,
      "style": {
        "width": 500
      }
    },
    {
      "property": "pathToField",
      "label": "Path to Field",
      "labelId": "au.permitsLookup.pathToField",
      "searchable": true,
      "sortable": true,
      "display" : true,
      "style": {
        "width": 900
      }
    },
    {
      "property": "validFrom",
      "label": "Valid From",
      "labelId": "au.permitsLookup.validFrom",
      "searchable": true,
      "sortable": true,
      "display" : true,
      "style": {
        "width": 600
      },
      "formatters": [
        { func: 'date', args: {'milliseconds': true}}
      ]
    }
  ]
}

export default class LookupResult extends React.Component {
  lookupData = JSON.parse(sessionStorage.getItem('permitsLookup'));
  lookupRules = JSON.parse(sessionStorage.getItem('rules'));
  lookupGroups = JSON.parse(sessionStorage.getItem('groups'));
  permitsEndpoint = new TMC.services[`${'Processing-crud'}`]({ apiVersion: '1'}).permits;


  constructor(props) {
    super(props);

    this.state = {
      lookup: {},
      data: [],
      rules: [],
      groups: [],
      timezone: moment.tz.guess()
    };

    if (this.lookupData) {
      this.permitsEndpoint.permits(this.lookupData).then((resp) => {
        resp.data.map(result => result.fieldId = result.fieldId.toString());
        this.setState({ lookup: this.lookupData, data: resp.data, rules: this.lookupRules, groups: this.lookupGroups });
      }).catch(createResponseAlertMessage);
    }

    this.baseUrl = props.match.url.split('/').slice(0, -1).join('/');
  }


  componentWillUnmount() {
    sessionStorage.removeItem('permitsLookup');
    sessionStorage.removeItem('rules');
    sessionStorage.removeItem('groups');
  }

  renderLookupKeys() {
    const { lookup } = this.state;

    let lookupKeys = [
      lookup.topLevelMessageName && <div className={styles.name_key}><AutoIntl displayId={`au.permitsLookup.topLevelMessageName`}/></div>,
      lookup.namespace && <div className={styles.namespace_key}><AutoIntl displayId={`au.permitsLookup.namespace`}/></div>,
      lookup.consentsByPOU && fromJS(lookup.consentsByPOU).size !== 0 && <div className={styles.key}><AutoIntl displayId={`au.permitsLookup.consentsByPOU`}/></div>,
      lookup.tags && fromJS(lookup.tags).size !== 0 && <div className={styles.key}><AutoIntl displayId={`au.permitsLookup.tags`}/></div>,
      lookup.groupIds && lookup.groupIds.length !== 0 && <div className={styles.key}><AutoIntl displayId={`au.permitsLookup.groupIds`}/></div>,
      lookup.applicableRuleIds && lookup.applicableRuleIds.length !== 0 && <div className={styles.key}><AutoIntl displayId={`au.permitsLookup.applicableRuleIds`}/></div>,
    ];

    return lookupKeys;
  }

  getKeyValuePairs(value) {
    let keyValuePairs = [];
    for (const [key, val] of Object.entries(value)) {
      keyValuePairs.push(`${key}: ${val}`)
    }
   return <div>{keyValuePairs.join(', ')}</div>;
  }

  getGroupIds() {
    let groups = [];
    this.state.groups.map(group => groups.push(group[1]));
    return <div>{groups.join(', ')}</div>;
  }

  getApplicalbeRuleIds() {
    let rules = [];
    this.state.rules.map(rule => rules.push(rule[0] + ' ' + rule[1]));
    return <div>{rules.join(', ')}</div>;
  }

  renderLookupValues() {
    const { lookup } = this.state;

    let lookupValues = [
      lookup.topLevelMessageName && <div>{lookup.topLevelMessageName}</div>,
      lookup.namespace && <div>{lookup.namespace}</div>,
      lookup.consentsByPOU && this.getKeyValuePairs(lookup.consentsByPOU),
      lookup.tags && this.getKeyValuePairs(lookup.tags),
      lookup.groupIds && this.getGroupIds(),
      lookup.applicableRuleIds && this.getApplicalbeRuleIds(),
    ];

    return lookupValues;
  }

  onTimeZoneChange = this.onTimeZoneChange.bind(this);
  onTimeZoneChange(timezone) {
    this.setState({ timezone });
  }

  onSearchTextChange = this.onSearchTextChange.bind(this);
  onSearchTextChange(searchText) {
    searchText = searchText.trim();
    return new Promise(res => {
      if (searchText !== this.state.searchText) {
        this.setState({ searchText }, () => res(true));
      } else {
        res(false);
      }
    });
  }

  getSearchText() {
    return this.state.searchText;
  }

  render() {
    const { data, lookup } = this.state;

    let results = fromJS(data).size !== 0;

    return (
      <div className={styles.container}>
        <div className={styles.headers}>
          <Breadcrumbs
            crumbs={[
              { key: "Rules", displayId: "au.entity.title.rules", destination: this.baseUrl + '/rules/list' },
              { key: "Results", displayId: "au.permitsLookup.permitsForMessage" },
            ]}
          />
          <div className={styles.search_container}>
            <SearchBox
              placeholder={tableDef.searchboxPlaceholder}
              onChange={this.onSearchTextChange}
            />
            {results && <div className={styles.text}>
              <FormattedMessage
                id="au.searchList.success.message"
                values={{
                  value: fromJS(data).size,
                  count: <div className={styles.count}><FormattedNumber value={fromJS(data).size} /></div>,
                  entityName: ( // single vs plural form
                    formatMessage({ id: `au.entity.${fromJS(data).size === 1 ? 'name' : 'title'}.fields` })
                  )
                }}
              />
            </div>}
          </div>
        </div>
        {fromJS(lookup).size !== 0 && <div className={cn(styles.search_result_container, {[styles.top_margin]: results})}>
          <div className={styles.keys}>
            {this.renderLookupKeys()}
          </div>
          <div className={styles.values}>
            {this.renderLookupValues()}
          </div>
        </div>}
        <div className={styles.table_container}>
          <TimezoneSelector onChange={this.onTimeZoneChange} className={styles.timezone_selector}/>
          <DynamicDataTable
            tablePkField={tableDef.tablePkField}
            columns={tableDef.columnDefs}
            sortBy={tableDef.sort?.columnId}
            data={fromJS(this.state.data)}
            sortDirection={tableDef.sort?.direction}
            searchText={this.getSearchText()}     
            timezone={this.state.timezone}
            formatters={{
              date({ value, momentFormat, milliseconds }) {
                const sessionTimezone = localStorage.getItem('timezone');
                if (!value) {
                  return false;
                }
            
                const dateOptions = getDateOptions({ timezone: sessionTimezone ? sessionTimezone: TZ, momentFormat, milliseconds });
            
                if (dateOptions.timezone) {
                  // This is needed because FF and IE do not appear to support IANA timezone formats
                  return moment.tz(value, dateOptions.timezone).format(dateOptions.momentFormat);
                }
            
                return <FormattedDate {...dateOptions} value={value} />;
              },
            }}
          />
        </div>
      </div>
    );
  }
}